import React from 'react';
import styles from './welcomingPage.module.css';
import googlePlayImage from '../assets/externalSites/googlePlay.png';
import appStoreImage from '../assets/externalSites/appStore.png';
import logo from '../assets/externalSites/Logo_white.png';
import linkedinIcon from '../assets/externalSites/linkedin.png';  // Add LinkedIn icon
import instagramIcon from '../assets/externalSites/instagram.png';  // Add Instagram icon
import ButtonEffectHover from '../effects/HoverEffectButton';
import Footer from './components/footer';
import { FaLinkedin, FaInstagram } from 'react-icons/fa';

const WelcomingPage: React.FC = () => {
  return (
    <div className={styles.welcomingPage}>
      <div className={styles.card}>
        <div className={styles.cardContent}>
          <img src={logo} alt="WorXInn Logo" className={styles.logo} />
          <h1>Working Made Easy</h1>
          <p>
            Welcome to WorXInn.
          </p>
          <p>
            You have landed here because you have been invited to be an early adopter and offered free Premium Membership for life.
            Premium Membership is still some months off but we are offering it to friends and supporters now ahead of its release to ensure we don't somehow miss your registration during general release.
          </p>
          <p>
            We think Premium membership even for a lite user will be worth about €360 pa.
          </p>
          <p>
            Choose any of the 2 links below or the logo to check out WorXInn in Beta and register/download.
          </p>

          <div className={styles.cardLinks}>
            <a href="https://play.google.com/store/apps/details?id=app.run.a.worxinnweb_ej6tzvcmqa_ew.twa&pli=1" target="_blank" rel="noopener noreferrer">
              <img src={googlePlayImage} alt="Get it on Google Play" className={styles.cardLinkImage} />
            </a>
            <a href="https://apps.apple.com/us/app/worxinn/id6502445615" target="_blank" rel="noopener noreferrer">
              <img src={appStoreImage} alt="Download on the App Store" className={styles.cardLinkImage} />
            </a>
            <ButtonEffectHover
              text={`Continue to web`}
              onClick={() => window.location.href = '/'}
              isSelected={false}
            />
          </div>

          <div className={styles.socialMediaLinks}>
            <a href="https://www.linkedin.com/company/plus10-coworking-and-innovation-space/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin size={40} />
            </a>
            <a href="https://www.instagram.com/worxinn_?igsh=MTB3ZHZibG13bW1mdA%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer">
              <FaInstagram size={40} />
            </a>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WelcomingPage;
